import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import DOMPurify from 'dompurify';
import Helmet from "react-helmet"

// const APOLLO_QUERY_WORK = gql`
//   {
//   	  works(first: 500, where: {orderby: {order: DESC, field: META}, metaQuery: {metaArray: {key: "date", type: DATE}}}) {
// 	    nodes {
// 	      work {
// 	        image1 {
// 	          mediaItemUrl
// 	        }
// 	        date
// 	      }
// 	      title
// 	      databaseId
// 	    }
// 	  }

//   }
// `

const APOLLO_QUERY_WORK = gql`
  {
  	  works(first: 500, where: {orderby: {order: DESC, field: DATE}}) {
	    nodes {
	      work {
	        image1 {
	          mediaItemUrl
	        }
	      }
	      title
	      databaseId
	      date
	    }
	  }

  }
`

const sanitizer = DOMPurify.sanitize;
let content = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;

const IndexPage = () => {

	return (
	  <Layout>
	    <SEO title="Work" />

	    <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Let an experienced copywriter help you</title>
            <meta name="title" content="Let an experienced copywriter help you" />
            <meta name="description" content="Are you looking for an experienced copywriter to help you with blogs, articles, websites and tone of voice? View a selection of my work." />
        </Helmet>

	    <Grid container className="main" style={{ display:'flex', alignItems:'flex-start' }}>

	    	<Grid container className="workpage">
	    		<h1>Work</h1>

		    		<Grid container className="work_text">

			    		 <Query query={APOLLO_QUERY_WORK}>

		                      {({data}) => {

			                      if(data){
			                        
			                         content = data.works.nodes.map((item, index) => {

			                         	{/*console.log(item.work)*/}

			                            return (

			                            	<Link key={index} className="blog_link" to={`/work_item/?id=${item.databaseId}`}>
				                            	<Grid container className="work_row">

				                            	<Grid item className="work_thumbnail_holder"><div className="work_thumbnail" style={{ backgroundImage: `url("${item.work.image1.mediaItemUrl}")` }}></div></Grid>

												{/*<Grid item className="blog_title">{item.title}</Grid>*/}

												</Grid>
											</Link>

			                            )

			                          })

			                          
			                      }

		                      return content

		                    }}

		                </Query>
			    		
		    		</Grid>

	    	</Grid>

	    </Grid>
	    
	  </Layout>
	)

}

export default IndexPage
